import React from 'react';
import './BackgroundImage.css';

function BackgroundImage({ image }) {  // Accept image as a prop

    if (image == "black") {
        return (
            <div
                className="background-image"
                style={{ backgroundColor: 'black' }}
            ></div>
        );
    } else {
        return (
            <div
                className="background-image"
                style={{
                    backgroundImage: `url(${image})`,  // Use the prop to set the background image
                }}
            ></div>
        );
    }
}

export default BackgroundImage;
