import React, { useState, useEffect } from 'react';
import './Home.css';
import BackgroundImage from '../Utilities/BackgroundImage';
import ImageBox from '../Boxes/ImageBox';
import TextBox from '../Boxes/TextBox';
import SpotifyBox from '../Boxes/SpotifyBox';
import InquiryBox from '../Boxes/InquiryBox';
import QuoteBox from "../Boxes/QuoteBox";
import SocialBox from "../Boxes/SocialBox";

import duck_greiffensee from '../../images/duck_greiffensee.jpg';
import moon from '../../images/moon.jpg';
import tomorrowland from '../../images/tomorrowland.jpg';
import chrissie_jungle from '../../images/chrissie_jungle.jpg';
import bilbao_crane from '../../images/bilbao_crane.jpg';
import calais_cam from '../../images/calais_cam.jpg';
import costa_sunset from '../../images/costa_sunset.jpg';

import duck_greiffensee_thumb from '../../images/thumbs/duck_greiffensee_thumb.jpg';
import moon_thumb from '../../images/thumbs/moon_thumb.jpg';
import tomorrowland_thumb from '../../images/thumbs/tomorrowland_thumb.jpg';
import chrissie_jungle_thumb from '../../images/thumbs/chrissie_jungle_thumb.jpg';
import bilbao_crane_thumb from '../../images/thumbs/bilbao_crane_thumb.jpg';
import calais_cam_thumb from '../../images/thumbs/calais_cam_thumb.jpg';
import costa_sunset_thumb from '../../images/thumbs/costa_sunset_thumb.jpg';


function Spacer({ height, show }) {
    return show ? <div style={{ height: `${height}px` }}></div> : <div style={{ height: `10px` }}></div>;
}

function Home() {
    const [showSpacers, setShowSpacers] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const checkWindowSize = () => {
            const columnsWidth = 450 * 3;
            const padding = 2 * 2 * 16;
            const totalWidth = columnsWidth + padding;
            setShowSpacers(window.innerWidth >= totalWidth);
        };

        checkWindowSize();
        window.addEventListener('resize', checkWindowSize);

        return () => window.removeEventListener('resize', checkWindowSize);
    }, []);

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <BackgroundImage image={"black"} />
            <div className="home">
                <div className="content">
                    <div className="column-wrapper">
                        <div className="column">
                            <div className="component" style={{order: 1}}>
                                <TextBox
                                    title="Hi! Glad you're here!"
                                    body="I made this website as a personal project and to give you a peek into my life. You can find some pictures I liked, my instagram and even one of my favorite playlists here."
                                    italicLine="Feel free to stick around and check out my socials!"
                                    textColor="white"
                                />
                            </div>
                            <div className="component" style={{order: 2}}>
                                <ImageBox
                                    thumbnailSrc={tomorrowland_thumb}
                                    fullSrc={tomorrowland}
                                    alt="Image 3"
                                    caption="Tomorrowland"
                                    onClick={handleImageClick}
                                />
                            </div>
                            <div className="component" style={{order: 3}}>
                                <ImageBox
                                    thumbnailSrc={duck_greiffensee_thumb}
                                    fullSrc={duck_greiffensee}
                                    alt="Image 1"
                                    caption="Greifensee, Switzerland"
                                    onClick={handleImageClick}
                                />
                            </div>
                            <div className="component" style={{order: 5}}>
                                <TextBox
                                    title="My Equipment"
                                    body="I mostly use a MacBook Pro but also have a Windows PC. My camera is a FujiFilm X T-50 and I'm equipped with a DJI Mini 3 Pro drone."
                                    italicLine="Feel free to ask me about my gear!"
                                    textColor="white"
                                />
                            </div>
                        </div>


                        <div className="column">
                            <div className="component" style={{order: 1}}>
                                <ImageBox
                                    thumbnailSrc={chrissie_jungle_thumb}
                                    fullSrc={chrissie_jungle}
                                    alt="Image 4"
                                    caption="Azores, Portugal"
                                    onClick={handleImageClick}
                                />
                            </div>
                            <div className="component" style={{order: 2}}>
                                <TextBox
                                    title="Who I am"
                                    body="I'm a computer science student from Switzerland. I love to travel and recently found interest in photography. I'm also a big fan of music and technology."
                                    italicLine="Thanks for your patience!"
                                    textColor="white"
                                />
                            </div>
                            <div className="component" style={{order: 3}}>
                                <ImageBox
                                    thumbnailSrc={moon_thumb}
                                    fullSrc={moon}
                                    alt="Image 2"
                                    caption="Uster, Switzerland"
                                    onClick={handleImageClick}
                                />
                            </div>
                            <div className="component" style={{order: 4}}>
                                <SocialBox
                                    title="My Socials"
                                    socialLinks={[
                                        {platform: "instagram", link: "https://www.instagram.com/basilfurrer"},
                                        {platform: "github", link: "https://github.com/b1s9l"},
                                    ]}
                                    iconColor="white"
                                    textColor="white"
                                />
                            </div>
                            <div className="component" style={{order: 5}}>
                                <ImageBox
                                    thumbnailSrc={calais_cam_thumb}
                                    fullSrc={calais_cam}
                                    alt="Calais, France"
                                    caption="Calais, France"
                                    onClick={handleImageClick}
                                />
                            </div>
                        </div>

                        <div className="column">
                            <div className="component" style={{order: 1}}>
                                <SpotifyBox playlistUrl="4sQhBcasi0aFaxaolWDdeI"/>
                            </div>
                            <div className="component" style={{order: 2}}>
                                <QuoteBox
                                    content="Beginnings always hide themselves in ends."
                                    italicLine="~Mike Posner"
                                    textColor="white"
                                />
                            </div>
                            <div className="component" style={{order: 3}}>
                                <ImageBox
                                    thumbnailSrc={costa_sunset_thumb}
                                    fullSrc={costa_sunset}
                                    alt="Costa Rica Beach"
                                    caption="Costa Rica"
                                    onClick={handleImageClick}
                                />
                            </div>
                            <div className="component" style={{order: 5}}>
                                <ImageBox
                                    thumbnailSrc={bilbao_crane_thumb}
                                    fullSrc={bilbao_crane}
                                    alt="Bilbao Skyline"
                                    caption="Bilbao, Spain"
                                    onClick={handleImageClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {selectedImage && (
                    <div className="modal" onClick={handleCloseModal}>
                        <img src={selectedImage} alt="Full size" className="modal-image"/>
                    </div>
                )}
            </div>
        </>
    );
}

export default Home;
